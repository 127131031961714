<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>disability-blind-read-finger</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M8.817,18.418a19.96,19.96,0,0,0-6.66-1.307A1.5,1.5,0,0,1,.75,15.6V2.253A1.5,1.5,0,0,1,2.357.753c6.3.329,9.624,2.574,9.643,4.3"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M23.25,16.5V2.253a1.5,1.5,0,0,0-1.607-1.5c-6.3.329-9.624,2.574-9.643,4.3"
    />
    <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" x1="12" y1="5.051" x2="12" y2="8.953" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M3.75,13.1a.375.375,0,1,1,.375.375A.375.375,0,0,1,3.75,13.1"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M3.75,8.6a.375.375,0,1,1,.375.375A.375.375,0,0,1,3.75,8.6"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M3.75,4.1a.375.375,0,1,1,.375.375A.375.375,0,0,1,3.75,4.1"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M8.25,7.1a.375.375,0,1,1,.375.375A.375.375,0,0,1,8.25,7.1"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M8.25,11.625A.375.375,0,1,1,8.625,12a.375.375,0,0,1-.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M19.5,10.875a.375.375,0,1,1,.375.375.375.375,0,0,1-.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15,7.125a.375.375,0,1,1,.375.375A.375.375,0,0,1,15,7.125"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M19.5,6.375a.375.375,0,1,1,.375.375.375.375,0,0,1-.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M23.136,23.25l.1-1.122a3,3,0,0,0-2.337-3.2l-3.651-.811V12.375a1.875,1.875,0,0,0-3.75,0v9.2l-1.477-1.108a1.533,1.533,0,0,0-2.146,2.146l.474.632"
    />
  </svg>
</template>
